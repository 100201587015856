.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px;
  overflow: hidden;
}

.container {
  max-width: 390px;
  max-height: 780px;
  height: 100%;
  text-align: center;
  margin: auto;
  position: relative;
  width: 100%;
  &.loaded {
    background-color: #fff;
  }
}

.icon {
  position: absolute;
  z-index: 20;
  display: flex;
  right: -40px;
  top: 15px;
  padding: 5px;
  cursor: pointer;
  svg {
    fill: #ffffff;
  }
}

@media (max-width: 490px) {
  .wrapper {
    padding: 0;
  }
  .container {
    max-width: 100%;
    max-height: 100%;
  }
  .icon {
    background-color: #ffffff;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    position: static;
    padding: 12px;
    svg {
      fill: #000000;
    }
  }
}
